import { sendRequest } from "utils/request_sender"
import { rekenModelUrls } from "./urls"

export class CalculationService {
    constructor() {}


    createLinearCalculation = async (
        token: string,
        setLoading: Function,
        objectId: number,
        payload: any,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: rekenModelUrls.CREATE_LINEAR_CALCULATION(objectId),
            method: 'post',
            body: payload,
            cb: (data: any) => {
                cb(data);
            },
            failCb: (err: any, status: any) => {
                failCb({ error: err, status: status})
            }
        })
    }
    getLinearCalculationResult = async (
        token: string,
        setLoading: Function,
        objectId: number,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: rekenModelUrls.GET_LINEAR_CALCULATION(`${objectId}`),
            method: "get",
            cb: (data: any) => {
                cb(data);
            },
            failCb: (error) => {
                failCb({ error: error.data, status: error.status})
            }
          })
    }
}