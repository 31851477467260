import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import authConfig, { auth0Config, msalConfig } from "authConfig";
import ConstruCoderAuthContext, {  ConstruCoderProviderType, initialAuthContext }  from "contexts/AuthContext";
import { useEffect, useState } from "react";
import { ls} from 'App';
export const useAuth = () => {
    // State
    const [isLoading, setLoading] = useState(false);
    const [ isLoaded, setIsLoaded] = useState(false);
    
    // Constants
    const provider: ConstruCoderProviderType = authConfig.provider;
    const auth = { ...initialAuthContext, provider: provider, isLoading: () => false};
    const userKey = 'user';
    const erroStatusKey = "erroStatus";
    const getAvatar = (name: string) => {
        return "https://ui-avatars.com/api/?name=" + encodeURI(name) + "&background=random&size=96&rounded=true"
    }

    if (provider === 'aad') {
        const { instance, inProgress, accounts} = useMsal();
        const isAuthenticated = useIsAuthenticated();
        
        console.log(`Progress: ${inProgress} - Authenticated: ${isAuthenticated}`)
        const getToken = async (config: any): Promise<AuthenticationResult> => {
            return await instance.acquireTokenSilent({
                ...config,
                account: accounts?.[0]
            }); 
        };
        auth.login = async () => await instance.loginRedirect({ ...msalConfig.loginRequest });
        auth.getAccessToken = async (config: any): Promise<AuthenticationResult> => {
            return await instance.acquireTokenSilent({
                ...config,
                account: accounts?.[0]
            }); 
        }; 

        auth.getUserInfo = async (config: any) => {
            auth.isLoading = () => true;
            let user = ls.get(userKey);
            if (!user) {
            try {
                let token = await getToken(config);
                user = {
                    email: token.account?.username,
                    roles: (token.idTokenClaims as any)['roles'] as string[],
                    id_token: token.idToken,
                    name: token.account!.name,
                    profilePhoto: getAvatar(token.account!.name!)
                };
                ls.set(userKey, user);
            } catch(ex) {
                user = undefined;
            }
        }
        if (user && !user.roles) {
            ls.set(erroStatusKey, 403);
        }
        auth.isLoading = () => false;
        return user;
        }
        auth.logout = async () => {
            ls.remove(userKey);
            ls.remove(erroStatusKey);
            await instance.logout({ onRedirectNavigate: (url) => { }})
        }
        auth.isAuthenticated = () => {
            console.log(isAuthenticated);
            return isAuthenticated;
        };
        auth.isLoading = () =>  {
            console.log(`${inProgress} - ${!(!inProgress || inProgress === InteractionStatus.None)}`);
            return !(!inProgress || inProgress === InteractionStatus.None);
        };
        useEffect(() => {  auth.isAuthenticated = () => isAuthenticated}, [isAuthenticated]);
        useEffect(() => { setLoading(!inProgress || inProgress === InteractionStatus.None); } , [inProgress]);
        useEffect(() => { auth.isLoading = () => isLoading}, [isLoading]);


    } else if (provider === 'auth0') {
        const auth0 = useAuth0();
        const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
        const namespace = "https://construcoder.cadblanche.eu"
        auth.getAccessToken = async () => await auth0.getAccessTokenSilently();
        auth.getUserInfo = async () => {
            auth.isLoading = () => true;
            var user = ls.get(userKey);
            if (!user) {
                let token = await auth0.getIdTokenClaims();
                user = {
                    email: auth0.user!.email,
                    roles: token![`${namespace}/roles`],
                    id_token: token?.__raw,
                    name: token?.name,
                    profilePhoto: getAvatar(token!.name!)
                };
                ls.set(userKey, user);
            }
            if (user && !user.roles) {
                ls.set(erroStatusKey, 403);
            }
            auth.isLoading = () => false;
            return user;
        };
        auth.login = async () =>  {
            return await auth0.loginWithRedirect();
        }
        auth.logout = async () => {
            ls.remove(userKey);
            ls.remove(erroStatusKey);
            return await auth0.logout({ 
                returnTo: window.location.origin,
                localOnly: false,
                client_id: auth0Config.auth0_client_id
            });
        }
        auth.isAuthenticated = () => auth0.isAuthenticated;
        auth.isLoading = () => auth0.isLoading;


    } else {

    }

    
    return {
        ...auth
    }
}