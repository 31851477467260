import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { toBase64 } from 'utils/toBase64';

interface Props {
    file?: any;
    setFile?: React.Dispatch<React.SetStateAction<any>>
}

const AppFileInput:React.FC<Props> = ({ file, setFile }) => {
  return (
    <Stack direction={"row"} sx={{height: 42, overflow: "hidden",  borderRadius: "5px !important",}}>
        <Button color="primary" sx={{"&:hover": {
            backgroundColor: "error.main"
        }, backgroundColor: "error.main", maxWidth: "86px", width: "100%", height: "42px", borderTopRightRadius: 0, borderBottomRightRadius: 0}} variant="contained" component="label">
            <Typography variant="caption" color="white">
                Choose File
            </Typography>
            <input hidden accept="image/*" multiple type="file" onChange={(e) => {
                if(setFile instanceof Function) {
                    const file = e.target.files?.[0]
                    if(file) {
                        toBase64(file).then(base64 =>  setFile(base64))
                    }
                }
            }} />
        </Button>
        <Box sx={{borderWidth: "1px", borderStyle: "solid", borderColor: "grey.400", flexGrow: 1, display: "flex", alignItems: "center", padding: "8px", borderTopRightRadius: 5, borderBottomRightRadius: 5, maxWidth: "calc(100% - 86px)"}}>
            <Typography whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>{file?.name}</Typography>
        </Box>
    </Stack>
  )
}

export default AppFileInput